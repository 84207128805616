import type { ArticleDto } from '~/typesAuto/apicore/v1'
import type { CalenderDto, PaperDto } from '~/typesAuto/apicore/v2'
import type { NormalizedPaperSagaDto } from '~/typesManual/normalized'

export const getMmPaperColor = (paperSlug: string): string | undefined => {
  const paperColors: { [key: string]: string | undefined } = {
    velfaerd: '#fd8603',
    ledelse: '#efabab',
    teknologi: '#89bdc9',
    'groen-omstilling': '#589b67',
    økonomi: '#f6bc2d',
  }
  return paperColors[paperSlug]
}

export const getArticlePaperDto = (
  article: ArticleDto | CalenderDto
): PaperDto | undefined => {
  const sagaPaperStore = useSagaPaperStore()
  const activePaperDto = article.papers?.find(
    (paperDto) => paperDto.paperId === sagaPaperStore.activePaper.id
  )

  return activePaperDto
}

export const getArticlePrimarySagaPaper = (
  article: ArticleDto | CalenderDto
): NormalizedPaperSagaDto | undefined => {
  const sagaPaperStore = useSagaPaperStore()

  const primaryPaperList = article.papers?.filter(
    (paper) => paper.primary === 1
  )

  if (primaryPaperList && primaryPaperList.length > 0) {
    const paperId = primaryPaperList[0].paperId

    return sagaPaperStore.getNormalizedPaperList.find(
      (sagaPaper) => sagaPaper.id === paperId
    )
  }

  return undefined
}
